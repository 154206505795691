var apiUrl // = process.env.REACT_APP_API_URL;
// apiUrl = "http://localhost:1120/api"

if (!apiUrl) 
    apiUrl = `${window.location.protocol}//${window.location.host}/api`;

var wsApiUrl = apiUrl.replace("http","ws")

console.info("apiUrl",apiUrl)
console.info("wsApiUrl",wsApiUrl)

export { apiUrl, wsApiUrl }